* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-size: 13px;
}

.container {
  display: flex;
}

/* Side Bare Styling start from here ============================================================================================================================ */
.main-container {
  display: flex;
  margin-top: 20px;
}

.sidebar {
  background-color: white;
  color: #1976d2;
  height: 100vh;
  box-shadow: 5px 8px 8px gray;
  border-radius: 5px;
  margin-right: 15px;
}

.main {
  flex-grow: 2;
}

/* styling top logo   */
.top_section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 10px;
}

.bars {
  cursor: pointer;
}

.logo {
  /* font-size: 20px; */
  line-height: 0;

}



/* styling serch section  */
.search {
  display: flex;
  align-items: center;
  margin: 10px 0;
  height: 30px;
  padding: 10px;
}

.serach_icon {
  display: flex;
}

.search input {
  border: none;
  /* width: 140px; */
  margin-left: 10px;
  border-radius: 5px;
  background-color: rgb(238, 238, 238);
  color: white;
  /* padding: 5px 10px; */
}


/* Styling menu List  */

.link {
  display: flex;
  /* color: white; */
  color: #1976d2;
  gap: 10px;
  padding: 5px 10px;
  border-right: 4px solid transparent;
  transition: 0.2s cubic-bezier(0.6, -0.28, 0.375, 0.045);

}

a {
  text-decoration: none;
}

/* Apply hover effect  */

.link:hover {
  border-right: 4px solid rgb(14, 14, 14);
  transition: 0.2s cubic-bezier(0.6, -0.28, 0.375, 0.045);
  /* background-color: rgb(45,51,89); */
  background-color: #1976d2;
  color: white;
}

.link_text {
  white-space: nowrap;
  font-size: 15px;
}


.main-container {

  display: flex;
  margin-top: 20px;
}





.appWrapper {
  display: flex;
  flex-direction: column;
  /* height: 90vh; */
}

.headerWrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
  /* flex-direction: column;
  height: 100vh; */
  
}

.appContent {
  display: flex;
  flex-grow: 1;
  padding-top: 80px;
  
}











.sideMenu {
  position: fixed;
  top: 100px;
  /* Adjust this value to match your header's height */
  bottom: 0;
  left: 0;
  width: 200px;
  background-color: #f5f5f5;
  overflow-y: auto;
}

.pageContentWrapper {
  margin-left: 200px;
  /* Adjust this value to match your side menu's width */
  flex-grow: 1;
  overflow-y: auto;
  padding-bottom:50px ;
}

.appWrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}


.footerWrapper {
  background-color: #ccbdbd;
  padding: 10px;
  color: white;
  /* text-align: center; */
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
}


 
/* DataTable.css (Create a new CSS file for modern styling) */
 .data-table-container {
   max-width: 100%;
   overflow-x: auto;
   padding: 10px;
 }
 
 .table {
   width: 100%;
   border-collapse: collapse;
   text-align: left;
   font-family: Arial, sans-serif;
 }
 
 .table th,
 .table td {
   /* padding: 12px; */
   border-bottom: 1px solid #f0f0f0;
 }

 .table td{
  padding: 6px;
 }
 
 .table th {
   background-color: #f8f9fa;
   color: #464545;
 }
 
 .table .even-row {
   background-color: #f7f7f7;
 }
 
 .table img {
   max-width: 50px;
   height: auto;
   border-radius: 50%;
 }
 
 .pagination-container {
   display: flex;
   justify-content: space-between;
   align-items: center;
   /* margin-top: 20px; */
 }
 
 .pagination {
   display: flex;
   align-items: center;
 }
 
 .pagination button {
   margin: 0 5px;
   cursor: pointer;
   border: none;
   background-color: #007bff;
   color: #fff;
   padding: 5px 8px;
   border-radius: 5px;
   font-size: 14px;
 }
 
 .pagination button:disabled {
   background-color: #ccc;
   cursor: not-allowed;
 }
 

 /* Rows per page Select  */
 .select-box {
   appearance: none;
   background-color: #f8f9fa;
   border: 1px solid #d1d1d1;
   padding: 5px 12px;
   border-radius: 5px;
   color: #333;
   cursor: pointer;
 }
 
 
 .rows-per-page {
   display: flex;
   align-items: center;
 }
 
 


/* Side Bare Styling end here ============================================================================================================================ */


@media (max-width: 700px) {
  .sidebar {
    width: '45px'
  }
}

.fade-appear {
  opacity: 0;
  /* transition: opacity 500ms ease-in; */
}

.fade-appear.fade-appear-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}





